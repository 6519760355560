#podcast {
  --remotion-font: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
  --remotion-sz: 48px;
}

#podcast .audio-viz {
  display: flex;
  flex-direction: row;
  height: calc(var(--remotion-sz) * 4);
  align-items: center;
  justify-content: center;
  gap: calc(var(--remotion-sz) * 0.25);
  margin-top: var(--remotion-sz);
}

#podcast .bar {
  border-radius: calc(var(--remotion-sz) * 0.25);
  width: calc(var(--remotion-sz) * 0.25);
}

#podcast .container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: white;
  padding: var(--remotion-sz);
  background-color: black;
  font-family: var(--remotion-font);
}

#podcast .row {
  display: flex;
  flex-direction: row;
}

#podcast .cover {
  border-radius: 6px;
  max-width: 30%;
}

#podcast .title {
  margin-left: var(--remotion-sz);
  line-height: 1.25;
  font-weight: 800;
  color: rgba(55, 65, 81, 1);
  font-size: var(--remotion-sz);
}

#podcast .captions {
  margin-top: calc(var(--remotion-sz) * 0.5);
  font-size: calc(var(--remotion-sz) * 1.5);
  font-weight: 600;
}

.promo-container {
  width: 100%;
  height: 100%;
  background-color: rgba(55, 65, 81, 1);
  font-family: var(--remotion-font);
}

.promo-composition {
  position: absolute;
  left: calc(var(--remotion-sz) * 9);
  top: calc(var(--remotion-sz) * -5);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: calc(var(--remotion-sz) * 0.75);
  overflow: hidden;
}

.free-template {
  width: calc(var(--remotion-sz) * 16);
  left: calc(var(--remotion-sz) * 0.75);
  font-size: calc(var(--remotion-sz) * 1.5);
  line-height: calc(var(--remotion-sz) * 2);
  font-weight: 800;
  filter: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  left: calc(var(--remotion-sz) * 0.75);
  top: calc(var(--remotion-sz) * 0.25);
  position: absolute;
  color: rgba(107, 114, 128, 1);
}

#podcast .description {
  color: rgba(107, 114, 128, 1);
  line-height: 1;
  font-weight: normal;
  font-size: var(--remotion-sz);
  filter: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  width: calc(var(--remotion-sz) * 13);
  left: calc(var(--remotion-sz) * 0.75);
  top: calc(var(--remotion-sz) * 2.5);
  position: absolute;
}

.tagline {
  font-weight: 800;
  font-size: calc(var(--remotion-sz) * 2.25);
  line-height: calc(var(--remotion-sz) * 2.5);
  color: rgba(251, 191, 36, 1);
  filter: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  width: calc(var(--remotion-sz) * 16);
  position: absolute;
  top: calc(var(--remotion-sz) * 7);
  left: calc(var(--remotion-sz) * 0.75);
}